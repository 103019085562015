<template>
  <div id="videoPlayer">
    <video :id="id" controls :muted="muted" autoplay></video>
    <span class="intranet" v-if="showIntranetText">仅支持内网环境</span>
  </div>
</template>

<script>
import flvjs from "flv.js";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    channelId: {
      type: String,
    },
    deviceRegisterId: {
      type: String,
    },
    videoUrl: {
      type: String,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    aliRtcAuthInfo: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  watch: {
    channelId: {
      handler(value) {
        value && this.getPlayerUrl();
      },
      immediate: true,
    },
    deviceRegisterId: {
      handler(value) {
        value && this.getPlayerUrl();
      },
      immediate: true,
    },
    videoUrl: {
      handler(url) {
        if (url) {
          this.destroy();

          this.$nextTick(() => {
            this.initVideo(url);
          });
        }
      },
      immediate: true,
    },
    isLocal() {
      if (this.channelId || this.deviceRegisterId) {
        this.getPlayerUrl();
      }
    },
    aliRtcAuthInfo: {
      handler(value) {
        value && this.initAliRTC();
      },
      immediate: true,
    },
  },
  data() {
    return {
      player: null,
      load: false,
      ws: null,
      videoElement: null,
      showIntranetText: false,
      aliWebRTC: null,
    };
  },
  computed: {
    id() {
      return "video" + uuidv4().split("-").join("");
    },
    isLocal() {
      return this.$store.state.isLocal;
    },
  },
  methods: {
    async transformStream(rtsp) {
      const res = await this.$http.post("http://localhost:65353/getPlayerUrl", {
        rtsp,
      });
      if (res.code === 0) {
        this.initVideo(res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取内网播放地址
    async getLocalPlayerUrl(rtsp) {
      try {
        const res = await this.$http.post(
          `http://localhost:${process.env.VUE_APP_LOCAL_FFMPEG_PORT}`,
          {
            rtsp,
          }
        );
        if (res.code === 0) {
          this.initVideo(res.data);
        } else {
          this.$message.error(res.msg);
          this.$emit("error", res.msg);
        }
      } catch (err) {
        this.$notify({
          title: "监控播放异常",
          message: "监测到内网视频所需的NodeJS脚本未启用",
          type: "error",
          duration: 0,
        });
      }
    },
    async getPlayerUrl() {
      if (this.videoUrl) {
        this.initVideo(this.videoUrl);
      } else {
        const url = this.channelId
          ? `/channel/start/play${this.isLocal ? "/local" : ""}/${
              this.channelId
            }`
          : `/device/register/start/play${this.isLocal ? "/local" : ""}/${
              this.deviceRegisterId
            }`;

        const res = await this.$http.get(url);
        if (res.code === 0) {
          if (res.data.startsWith("rtsp")) {
            this.showIntranetText = true;
            // 流媒体版本
            // this.transformStream(res.data);
            this.getLocalPlayerUrl(res.data);
          } else {
            this.initVideo(res.data);
          }
        } else {
          this.$message.error(res.msg);
          this.$emit("error", res.msg);
        }
      }
    },
    async initAliRTC() {
      this.aliWebRTC = new AliRtcEngine({
        autoPublish: true,
        autoSubscribe: true,
      });
      this.aliWebRTC.setAudioOnlyMode(true);

      if (await this.aliWebRTC.isSupport()) {
        try {
          await this.aliWebRTC.joinChannel(
            this.aliRtcAuthInfo,
            this.user?.userName ?? "--"
          );

          this.aliWebRTC.setDisplayRemoteVideo(
            await this.aliWebRTC.subscribe(this.aliRtcAuthInfo.channel),
            document.querySelector(`#${this.id}`),
            2
          );

          this.aliWebRTC.on("onUnPublisher", () => {
            this.destroy();
          });
        } catch (err) {
          console.log(err);
          this.$message.error(err.message);
        }
      }
    },
    progressHandler() {
      if (!this.load) {
        this.player.currentTime = this.player.buffered.end(0) - 1;
        this.load = true;
      }

      const end = this.player.buffered.end(0); //获取当前buffered值(缓冲区末尾)
      const delta = end - this.player.currentTime; //获取buffered与当前播放位置的差值

      // 延迟过大，通过跳帧的方式更新视频
      if (delta > 10 || delta < 0) {
        this.player.currentTime = this.player.buffered.end(0) - 1;
        return;
      }

      // 追帧
      if (delta > 1) {
        this.videoElement.playbackRate = 1.1;
      } else {
        this.videoElement.playbackRate = 1;
      }
    },
    initVideo(url) {
      this.destroy();

      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          this.player = flvjs.createPlayer(
            {
              type: "flv",
              isLive: true,
              url,
            },
            {
              // enableWorker: true, // 启用分离的线程进行转换
              enableStashBuffer: false, // 关闭IO隐藏缓冲区
              stashInitialSize: 128, // 减少首帧显示等待时长
              autoCleanupSourceBuffer: true,
            }
          );

          this.videoElement = document.getElementById(this.id);
          this.player.attachMediaElement(this.videoElement);
          this.player.load();
          this.player.play();

          this.videoElement.addEventListener("progress", this.progressHandler);

          this.player.on(flvjs.Events.MEDIA_INFO, () => {
            this.showIntranetText = false;
          });
          this.player.on(flvjs.Events.ERROR, (error) => {
            this.destroy();
            this.$emit("error", error);
            if (error === "NetworkError") {
              this.$message.error("网络错误");
            } else {
              this.getPlayerUrl();
            }
          });
        }
      });
    },
    destroy() {
      if (this.aliWebRTC) {
        this.aliWebRTC.leaveChannel();
        this.aliWebRTC.dispose();
      }

      if (this.videoElement) {
        this.videoElement.removeEventListener("progress", this.progressHandler);
      }

      if (this.player) {
        this.player?.pause();
        this.player?.unload();
        this.player?.detachMediaElement();
        this.player?.destroy();
        this.player = null;
      }
    },
  },
  beforeDestroy() {
    this.destroy();
  },
};
</script>

<style lang="scss">
#videoPlayer {
  width: 100%;
  height: 100%;
  position: relative;
  video {
    height: 100%;
    width: 100%;
  }
  video::-webkit-media-controls-timeline {
    display: none;
  }
  .intranet {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 1.5rem;
  }
}
</style>
