<template>
  <div id="bigScreen-passageway">
    <ul>
      <li v-for="item of passagewayChecked" :key="item.channelId">
        <videoPlayer
          :channelId="item.channelId"
          :muted="true"
          v-if="buttonList.includes('gangting-bigScreen-video')"
        />
        <div class="notPermission" v-else>
          <el-empty description="暂无权限"></el-empty>
        </div>
        <h6>{{ item.channelName }}</h6>
        <span
          v-if="item.channelType === '入口'"
          @click="opening(item.channelId, '入口')"
          >开闸</span
        >

        <span v-else @click="opening(item.channelId)">开闸</span>
        <span
          @click="manualPassing(item.channelId)"
          class="manualPassing"
          v-if="buttonList.includes('gangting-bigScreen-manual')"
          >模拟{{ item.channelType === "入口" ? "入场" : "出场" }}</span
        >
      </li>
    </ul>
    <div :key="refreshList" class="parkingList">
      <div
        class="parkingList-item"
        v-for="item of passagewayChecked"
        :key="item.channelId"
      >
        <div v-if="item.channelType === '入口'">
          <el-image
            :src="item.info.arrivalImage"
            fit="contain"
            :preview-src-list="[item.info.arrivalImage]"
            @error="imgError(item.info.arrivalImage)"
            :key="imgKey"
          >
            <div slot="error" class="image-slot">
              <img :src="require('@/assets/images/error.png')" alt="" />
            </div>
          </el-image>
          <div class="info_container">
            <p>
              <span :class="{ transparent: !item.info.plateNum }">{{
                item.info.plateNum
              }}</span>
              <span class="transparent">入场车辆</span>
            </p>
            <ul>
              <li>
                <strong>车辆类型：</strong>
                <span :title="item.info.leaseType">{{
                  item.info.leaseType
                }}</span>
              </li>
              <li>
                <strong>过期时间：</strong>
                <span :title="item.info.leaseExpireTime">{{
                  item.info.leaseExpireTime
                }}</span>
              </li>
              <li>
                <strong>车辆归属：</strong>
                <span :title="item.info.subjectName">{{
                  item.info.subjectName
                }}</span>
              </li>
              <li>
                <strong>入场时间：</strong>
                <span :title="item.info.arrivalTime">{{
                  item.info.arrivalTime
                }}</span>
              </li>
              <li>
                <strong>联系人：</strong>
                <span :title="item.info.contacts">{{
                  item.info.contacts
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <el-image
            :src="item.info.departureImage"
            fit="contain"
            :preview-src-list="[item.info.departureImage]"
            @error="imgError(item.info.departureImage)"
            :key="imgKey"
          >
            <div slot="error" class="image-slot">
              <img :src="require('@/assets/images/error.png')" alt="" />
            </div>
          </el-image>
          <div class="info_container">
            <p>
              <span :class="{ transparent: !item.info.plateNum }">{{
                item.info.plateNum
              }}</span>
              <span class="transparent">出场车辆</span>
            </p>
            <ul>
              <li>
                <strong>车辆类型：</strong>
                <span :title="item.info.leaseType">{{
                  item.info.leaseType
                }}</span>
              </li>
              <li>
                <strong>应付金额：</strong>
                <span :title="item.info.payAmount">{{
                  item.info.payAmount
                }}</span>
              </li>
              <li>
                <strong>停车时长：</strong>
                <span :title="item.info.parkingTime">{{
                  item.info.parkingTime
                }}</span>
              </li>
              <li>
                <strong>出场时间：</strong>
                <span :title="item.info.departureTime">{{
                  item.info.departureTime
                }}</span>
              </li>
              <li>
                <strong>联系人：</strong>
                <span :title="item.info.contacts">{{
                  item.info.contacts
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <openDialog ref="openDialog" />
    <manualPassingDialog ref="manualPassingDialog" />
  </div>
</template>

<script>
import videoPlayer from "@/components/videoPlayer.vue";

export default {
  components: {
    videoPlayer,
    openDialog: () => import("./openDialog.vue"),
    manualPassingDialog: () => import("./manualPassingDialog.vue"),
  },
  data() {
    return {
      player: [],
      imgErrorTimer: null,
      imgKey: true,
      refreshList: false,
    };
  },
  computed: {
    passagewayChecked() {
      return this.$store.state.GangTing.passagewayChecked;
    },
    socketInfo() {
      return this.$store.state.socket.info;
    },
  },
  watch: {
    socketInfo(info) {
      if (info.tag === "0410" || info.tag === "0411") {
        this.imgErrorTimer && clearTimeout(this.imgErrorTimer);
        this.refreshList = !this.refreshList;
      }
    },
  },
  methods: {
    manualPassing(channelId) {
      this.$refs.manualPassingDialog.channelId = channelId;
      this.$refs.manualPassingDialog.show = true;
    },
    async opening(channelId, releaseType) {
      if (releaseType === "入口") {
        this.$msgbox({
          title: "提示",
          message: "此操作将完成线上开闸, 是否继续?",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              try {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = "处理中...";
                let res = await this.$http.post("/channel/manual/opening", {
                  channelId,
                });
                if (res.code === 0) {
                  if (res.data) {
                    this.$message.success("开闸成功");
                  } else {
                    this.$message.error("开闸失败");
                  }
                } else {
                  this.$message.error(res.msg);
                }
              } catch (err) {
                this.$message.error(err);
              } finally {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }
            } else {
              done();
            }
          },
        });
      } else {
        this.$refs.openDialog.channelId = channelId;
        this.$refs.openDialog.show = true;
      }
    },
    imgError(imgUrl) {
      if (imgUrl) {
        this.imgErrorTimer = setTimeout(() => {
          this.imgKey = !this.imgKey;
        }, 500);
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("GangTing/SET_PARKINGLOTID", "");
  },
};
</script>

<style lang="scss">
#bigScreen-passageway {
  width: 54%;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;

  & > ul {
    width: calc(50% - 1.06rem);

    li {
      width: 100%;
      height: 29.81rem;
      position: relative;
      color: rgba(255, 255, 255, 0.96);
      margin-bottom: 1.19rem;
      border-radius: 0.31rem;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }

      h6 {
        width: 100%;
        height: 2.13rem;
        line-height: 2.13rem;
        box-sizing: border-box;
        position: absolute;
        font-size: 1rem;
        color: white;
        padding: 0 1.06rem;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
      }

      .video-js {
        width: 100%;
        height: 100%;
        position: relative;

        .vjs-big-play-button {
          /* 中间大的播放按钮 */
          font-size: 2.5em;
          line-height: 2.3em;
          height: 2.5em;
          width: 2.5em;
          -webkit-border-radius: 2.5em;
          -moz-border-radius: 2.5em;
          border-radius: 2.5em;
          background-color: rgba(115, 133, 159, 0.5);
          border-width: 0.12em;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .vjs-paused .vjs-big-play-button {
        /* 视频暂停时显示播放按钮 */
        display: block;
      }

      .vjs-error .vjs-big-play-button {
        /* 视频加载出错时隐藏播放按钮 */
        display: none;
      }

      & > span {
        display: inline-block;
        width: 5.69rem;
        height: 2.19rem;
        text-align: center;
        line-height: 2.19rem;
        background: #00cec0;
        font-size: 1.13rem;
        border-radius: 0.31rem;
        position: absolute;
        z-index: 2;
        bottom: 0.69rem;
        right: 1rem;
        cursor: pointer;
      }
      & > .manualPassing {
        transform: translateX(-120%);
      }
    }
  }

  .parkingList {
    width: calc(50% - 1.06rem);

    .parkingList-item {
      width: 100%;
      height: 29.81rem;
      padding: 0.5rem;
      box-sizing: border-box;
      background-color: #001f46;
      margin-bottom: 1.19rem;
      border-radius: 0.31rem;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      & > div {
        height: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;

        .el-image {
          width: 100%;
          height: calc(100% - 13rem);

          .image-slot {
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .info_container {
          width: 100%;
          height: 13rem;
          align-self: flex-end;

          & > p {
            width: 100%;
            margin: 1.25rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              color: rgba(0, 206, 193, 1);
              font-size: 1rem;
              display: inline-block;
              padding: 0.38rem 0.44rem;
              border: 0.06rem solid #00cec0;
              border-radius: 0.31rem;
            }

            .transparent {
              border-color: transparent;
            }
          }

          ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
              width: 100%;
              margin-bottom: 1rem;
              font-size: 1rem;
              white-space: nowrap;
              @include overflow-ellipsis;

              &:nth-child(1),
              &:nth-child(2) {
                width: 49%;
              }
              &:nth-child(5) {
                margin-bottom: 0;
              }

              strong {
                display: inline-block;
                width: 5rem;
                min-width: 60px;
                text-align: right;
                color: white;
                font-weight: normal;
              }

              span {
                display: inline-block;
                color: rgba(255, 255, 255, 0.63);
              }
            }
          }
        }
      }
    }
  }
  .notPermission {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-empty {
      padding: 0;
    }
  }
}
</style>
